import React from 'react'
import { graphql } from 'gatsby'
import KnowledgeCenterPageTemplate from 'templates/knowledge-center-page'

const KnowledgeCenter = props => {
  return <KnowledgeCenterPageTemplate {...props} />
}

export const pageQuery = graphql`
  {
    allMarkdownRemark(
      filter: { fields: { type: { eq: "KnowledgeCenter" } } }
      sort: { fields: [fields___letterGroup, frontmatter___feature], order: ASC }
    ) {
      nodes {
        frontmatter {
          feature
        }
        fields {
          letterGroup
        }
      }
    }
  }
`

export default KnowledgeCenter
